import { defineStore } from "pinia"
import { computed, ref } from "vue"
import { useStorage } from "@vueuse/core"

export const useUserNameStore = defineStore("userName", () => {
  const userNameInStorage = useStorage("pinia/userName/userName", "")

  const userName = ref("")

  const getUserName = computed((): string => {
    if ((userName.value === "" || !userName.value) && userNameInStorage.value !== "") {
      return userNameInStorage.value
    }

    return userName.value
  })

  const setUserName = (newUserName: string) => {
    userNameInStorage.value = newUserName
    userName.value = newUserName
  }

  return {
    userNameInStorage,
    userName,
    getUserName,
    setUserName,
  }
})
